/*
* 通用注册components下的所有组件
*/
const requireAll = requireContext => requireContext.keys().map(requireContext);
const req = require.context('./', true, /index\.vue$/);
const valueArr = requireAll(req);
const keyArr = req.keys().map(item => item.match(/.\/(\S*).vue/)[1]);
const component = {};
for (const idx in keyArr) {
    if (valueArr[idx].default.name) {
        component[valueArr[idx].default.name] = valueArr[idx].default;
    }
}
export default component;