<template>
    <FlexBox class="navbar" gap="30px">
        <div @mouseenter="onMouseOver(item,index)" @mouseleave="onMouseOut" v-for="(item, index) in appMenu" :key="index" class="baritem">
            <span class="name"  :class="{'is-active': item.path === path}" @click="go(item,index)">{{ item.meta?.title }}</span>
            <ul class="sub-bar" v-if="activeBar === item.meta.title" >
                <li @click="goSub(item,subItem)" v-for="(subItem,index) in barList[activeBar]" :key="index"><span>{{subItem.name}}</span></li>
            </ul>
        </div>

    </FlexBox>
</template>

<script>
import { appMenu } from "@/router"
export default {
    name: "Navbar",
    data() {
        return {
            appMenu,
            activeIndex: 0,
            activeBar: '',
            barList: {
                '关于我们': [{ id: 1, name: '公司简介' }, { id: 2, name: '组织架构' }, { id: 3, name: '联系我们' }],
                '新闻动态': [{ id: 1, name: '公司要闻' }, { id: 2, name: '行业资讯' }],
                '主营业务': [{ id: 1, name: '技术研发' }, { id: 2, name: '智能运维' }, { id: 3, name: '解决方案' }],
                '科技创新': [{ id: 1, name: '标准规范' }, { id: 2, name: '产学研合作' }, { id: 3, name: '联盟组织' }],
                '信息公开': [{ id: 1, name: '公示公告' }, { id: 2, name: '招标信息' }]
            }
        }
    },
    computed: {
        path() {
            return this.$route.path;
        }
    },
    methods: {
        go(item, index) {
            this.activeIndex = index;
            this.$router.push(item.path);
        },
        goSub(item, subItem) {
            this.$router.push({ path: item.path, query: { id: subItem.id }});
        },
        onMouseOver(item, index) {
            this.activeBar = item.meta.title;
            if (this.activeBar === '首页') return
            setTimeout(() => {
                const doc = document.getElementsByClassName('sub-bar')
                doc[0].style.display = "block";
            }, 0);
        },
        onMouseOut() {
            this.activeBar = ""
            const doc = document.getElementsByClassName('sub-bar');
            for (let i = 0; i < doc.length; i++) {
                doc[i].style.display = "block";
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar {
    white-space: nowrap;
    .baritem {
        cursor: pointer;
        user-select: none;
        position: relative;
        .sub-bar {
            display: none;
            position: absolute;
            top: 100px;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 999;
            background-color: #1B3586;
            padding: 10px;
            animation: top 1s;

                @keyframes top {
                    from {
                        top: 60px;
                        opacity: 0;
                    }

                    to {
                        top: 100px;
                        opacity: 1;
                    }
                }
            >li {
                color: #fff;
                padding: 10px 25px;
                font-weight: normal;
                font-size: 20px;
                &:hover {
                    color: #1B3586;
                    background-color: #fff;
                    border-radius: 4px;
                }
            }
        }

        .name {
            color: #FFFFFF;
            font-size: 20px;
            height: 100px;
            line-height: 100px;
            padding-bottom:4px;
             &.is-active {
            position: relative;
            font-weight: bold;

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                background: #fff;
            }
        }
        }
    }
}
</style>