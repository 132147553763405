<template>
    <div class="app-header">
        <div class="container">
            <img src="@/assets/imgs/home/logo.png" @click="$router.push('/')" />
            <div class="app-header-right">
                <div class="caicv"><a  href="http://www.caicv.org.cn/" target="_blank"></a></div>
                <Navbar />
            </div>
        </div>
    <!-- <div class="right-controls">
            <MyButton type="default" size="small" round>成宜高速</MyButton>
        </div> -->
    </div>
</template>

<script>
import Navbar from "./Navbar.vue";
export default {
    name: "AppHeader",
    components: { Navbar },
    props: {
        showLogo: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        go() {
            this.$router.push({ path: '/' })
        }
    }
};
</script>

<style lang="scss" scoped>
.app-header {
    background-color: #1b3586;
  .container {
    height: 100px;
    @include contentWidth;
    @include flexBetween;
    cursor: pointer;

    .app-header-right {
      .caicv {
        color: #fff;
        font-size: 16px;
        text-align: right;
        // margin-bottom: 20px;
        a, a:hover{
            color: #fff;
        }
      }
    }
    .right-controls {
      margin-left: auto;
    }
  }
}
</style>