<template>
    <div class="con-container">
        <div class="con-wrap">
            <div class="con-bar-left">
                <div class="curr-bar">{{currBar.title}}</div>
                <ul class="curr-bar-list">
                    <li class="curr-bar-item" :class="{'active':activeIndex===item.id}" v-for="(item,index) in currBarList" :key="index" @click="onClick(item)">
                        <span class="name">{{item.name}}</span>
                        <i class="img"></i>
                    </li>
                </ul>
            </div>
            <div class="con-bar-right">
                <ul class="con-crumbs">
                    <li class="con-crumbs-li" @click="$router.push('/')">首页</li>
                    <li class="con-crumbs-li" @click="goPath()">{{currBar.title}}</li>
                    <li class="con-crumbs-li" v-if="activeIndex!==0" @click="$router.push(currBar.path+'?id'+activeIndex)">{{currBarSub()}}</li>
                </ul>
                <div class="con-bar-details">
                    <slot />
                </div>
            </div>
        </div>
        <div class="line"></div>
    </div>
</template>
<script>
export default {
    name: 'ConBar',
    props: {
        currBarIndex: {
            type: Number,
            default: 0
        },
        currBar: {
            type: Object,
            default: () => {}
        },
        currBarList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            activeIndex: 0
        }
    },
    watch: {
        currBarIndex: {
            handler(nVal) {
                this.activeIndex = nVal;
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        onClick(item) {
            this.activeIndex = item.id;
            this.$emit('changeBar', item);
        },
        goPath() {
            this.$emit('goPath');
        },
        currBarSub() {
            return this.currBarList.filter(item => item.id == this.activeIndex)[0].name;
        }
    }
}
</script>
<style scoped lang="scss">
.con-container {
    position: relative;
}
.con-wrap {
    @include contentWidth;
    margin-bottom: 40px;
    margin-top: -5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .con-bar-left{
        margin-top: -50px;

        .curr-bar {
            width: 267px;
            height: 100px;
            line-height: 100px;
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
            position: relative;
            color: #000000;
            font-size: 28px;
            font-family: 'OPPOSans-B';
            padding-left: 30px;
            box-sizing: border-box;
            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                width: 0;
                height: 0;
                border-top: 17px solid transparent;
                border-right: 17px solid #0052D9;
            }
        }
        .curr-bar-list{
            margin-top: 40px;
            .curr-bar-item {
                margin-top: 20px;
                padding: 30px;
                box-sizing: border-box;
                width: 267px;
                height: 62px;
                font-size: 18px;
                color: #333333;
                background-color: #EBEBEB;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                &.active,&:hover {
                    background-color: #0052D9;
                    color: #fff;

                    .img {
                        background: url('@/assets/imgs/common/arrowR-h.png') no-repeat;
                    }
                }
                .img {
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    background: url('@/assets/imgs/common/arrowR.png') no-repeat;
                }
            }
        }
    }
    .con-bar-right {
        flex: 1;
        overflow: hidden;
        .con-crumbs{
            display: flex;
            flex-direction: row;
            justify-content:flex-end;
            .con-crumbs-li {
                color: #666666;
                font-size: 16px;
                // width: 103px;
                // height: 50px;
                // line-height: 50px;
                padding: 14.5px 20px;
                text-align: center;
                cursor: pointer;
                // &:hover {
                //     color: #0052D9;
                // }
                &:hover,&.active {
                    background-color: #0052D9;
                    color: #fff;
                }
            }
        }
        .con-bar-details{
            margin-top: 40px;
            margin-left: 20px;
        }
    }
}
.line {
    position:absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #DDDDDD;
}
</style>