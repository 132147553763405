<template>
    <ul class="news-list">
        <li class="news-list-item" v-for="(item,index) in conList" :key="index" @click="getDetails(item)">
            <div class="news-list-title">
                <span class="name"><i class="arrow"></i>{{item.title}}</span>
                <span class="time">{{ $dayjs(item.updateTime).format('YYYY-MM-DD') }}</span>
            </div>
            <div class="news-list-summary" v-if="item.summary">
                {{item.summary}}
            </div>
        </li>
    </ul>
</template>
<script>
export default {
    name: "ListH5",
    props: {
        conList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {

        }
    },
    methods: {
        getDetails(item) {
            this.$emit('getDetails', item);
        }
    }
}
</script>
<style scoped lang="scss">
 .news-list{
        margin-top: 18px;
        .news-list-item{
            margin-bottom: 24px;
            cursor: pointer;
            &:hover {
                .news-list-title {
                    .name {
                        color: #0052D9;
                    }
                }
            }
            .news-list-title {
                @include flexBetween;
                .name {
                    display: inline-block;
                    width: 60%;
                    color: #333;
                    font-size: 14px;
                    @include textEllipsis;
                    .arrow {
                        display: inline-block;
                        width: 14px;
                        height: 14px;
                        background: url("@/assets/imgs/common/arrow-b.png") no-repeat;
                        background-size: 100% 100%;
                        margin-right: 8px;
                    }
                }
                .time {
                    color: #666666;
                    font-size: 12px;
                }
            }
            .news-list-summary {
                margin-top: 4px;
                font-size: 12px;
                color: #666666;
                width: 80%;
                @include textEllipsis;
            }
        }
    }
</style>