<template>
    <el-button v-bind="$attrs" :type="type" :style="styles" :loading="btnLoading" v-on="listeners">
        <slot />
    </el-button>
</template>

<script>
export default {
    name: "MyButton",
    props: {
        // type不能已v-bind挂在，不然会导致button的type也被修改
        type: {
            type: String,
            default: null
        },
        width: {
            type: String,
            default: null,
        },
        padding: {
            type: String,
            default: null,
        },
        margin: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: null
        },
        bg: {
            type: String,
            default: null
        },
        border: { // 当type=text可以加
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            btnLoading: false,
        }
    },
    computed: {
        listeners() {
            return Object.assign({}, this.$listeners, {
                click: this.handleClick
            });
        },
        styles() {
            const styles = {};
            if (this.width) styles.width = this.width;
            if (this.padding) styles.padding = this.padding;
            if (this.margin) styles.margin = this.margin;
            if (this.color) styles.color = this.color;
            if (this.bg) {
                styles.background = this.bg;
                styles.border = `1px solid ${this.bg}`;
            }
            if (this.type == 'text' && this.border) styles.border = `1px solid ${this.color}`;
            return styles
        },
    },
    methods: {
        handleClick(e) {
            e.preventDefault();

            if (this.loading) {
                this.btnLoading = true;
                this.$emit('click', this.closeLoading);
            } else {
                this.$emit('click', e);
            }
        },
        closeLoading() {
            this.btnLoading = false;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>