<template>
    <div class="con-container">
        <span class="con-bar-item" @click="changeBar(item)" :class="{'active':activeIndex==item.id}"  v-for="(item,index) in currBarList" :key="index">{{item.name}}</span>
    </div>
</template>
<script>
export default {
    name: 'ConBarH5',
    props: {
        currBarIndex: {
            type: Number,
            default: 0
        },
        currBar: {
            type: Object,
            default: () => {}
        },
        currBarList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            activeIndex: 0
        }
    },
    watch: {
        currBarIndex: {
            handler(nVal) {
                this.activeIndex = nVal;
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        changeBar(item) {
            this.$emit('changeBar', item);
        }
    }
}
</script>
<style scoped lang="scss">
.con-container {
    position: relative;
     background-color: #fff;
    width: calc(100% - 32px);
    margin: 0 auto;
    margin-top: -30px;
    padding: 16px 30px;
    box-sizing: border-box;
    color: #666666;
    font-size: 14px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    .con-bar-item {
        position: relative;
        &.active {
            color: #000000;
            &::after {
                content: "";
                position: absolute;
                bottom: -6px;
                left: 50%;
                transform: translateX(-50%);
                width: 20px;
                height: 2px;
                background-color: #0052D9;
            }
        }
    }
}
</style>