export default [{
    // 事件告警
    path: "/event-alarm",
    component: () => import("@/views/app/eventAlarm"),
    meta: {
        title: "联系我们", icon: 'icon-event-alarm'
    }
},
// {
//     // 事件快处
//     path: "/event-quick",
//     component: () => import("@/views/app/eventQuick"),
//     meta: {
//         title: "事件快处", icon: 'icon-event-quick'
//     }
// }, {
//     // 主动管控
//     path: "/active-control",
//     component: () => import("@/views/app/activeControl"),
//     meta: {
//         title: "主动管控", icon: 'icon-active-control'
//     }
// }, {
//     // 视频巡查
//     path: "/video-patrol",
//     component: () => import("@/views/app/videoPatrol"),
//     meta: {
//         title: "视频巡查", icon: 'icon-video-patrol'
//     }
// }
]