<template>
    <div class="app-container">
        <component :is="device == 'mobile' ? 'AppHeaderH5' : 'AppHeader'" />
        <div class="app-body">
            <component :is="device == 'mobile' ? 'AppMainH5' : 'AppMain'" />
        </div>

        <BackTop targetName='.app-body' :right="100" :bottom="120" />
    </div>
</template>

<script>
import ResizeHandler from "@/layout/mixin/ResizeHandler";
import AppHeader from './components/AppHeader'
import AppHeaderH5 from './components/AppHeaderH5'
import AppMain from './components/AppMain'
import AppMainH5 from './components/AppMainH5'
import { mapGetters } from "vuex";
export default {
    components: { AppHeader, AppHeaderH5, AppMain, AppMainH5 },
    mixins: [ResizeHandler],
    computed: {
        ...mapGetters(['device']),
    },
}
</script>

<style lang="scss" scoped>
.app-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    @include flexColumn;

    .app-body {
        flex: 1;
        overflow: auto;
    }
}

::v-deep .el-backtop {
    width: 72px;
    height: 76px;
    background: #FFFFFF;
    color: #999;
    font-size: 12px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px 2px 2px 2px;
    text-align: center;
    vertical-align: middle;

    &:hover {
        color: #0052D9;
        box-shadow: 0px 0px 6px 0px rgba(0, 82, 217, 0.8);
    }
}
</style>