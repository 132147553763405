<template>
    <el-backtop :target="targetName" :right='right' :bottom="bottom">
        <div class="back-top-top">
            <i class="el-icon-upload2"></i><br>
            <b>TOP</b>
        </div>
    </el-backtop>
</template>
<script>
export default {
    name: 'BackTop',
    props: {
        targetName: {
            type: String,
            default: '.app-body'
        },
        right: {
            type: Number,
            default: 0
        },
        bottom: {
            type: Number,
            default: 0
        }
    }
}
</script>