<template>
    <div class="home-footer">
        <div class="home-footer-wrap">
            <div class="home-footer-left">
                <img src="@/assets/imgs/home/logo.png" @click="$router.push('/')" />
                <ul class="k-link-ul">
                    <li class="click-li"><span class="footer-msg" @click="$router.push('/news')">关于我们</span></li>
                    <li class="click-li"><span class="footer-msg" @click="$router.push('/inviteTenders')">新闻动态</span></li>
                    <li class="click-li"><span class="footer-msg" @click="$router.push('/business')">主营业务</span></li>
                    <li class="click-li"><span class="footer-msg" @click="$router.push('/technology')">科技创新</span></li>
                    <li class="click-li"><span class="footer-msg" @click="$router.push('/inviteTenders')">信息公开</span></li>
                </ul>
                <div class="filing-msg">
                    <span>
                        Copyright &copy; 2023 西部车网（重庆）有限公司<br>
                        <a href="https://beian.miit.gov.cn" target="_blank" class="white-link">渝ICP备2023001677号</a>
                        <img src="@/assets/imgs/common/20240711102336.png" height="16">
                        <a href="https://beian.mps.gov.cn/#/query/websearch?code=50009802001949" rel="noreferrer" target="_blank" class="white-link">渝公网安备50009802001949</a>
                    </span>
                </div>
            </div>
            <div class="contacts">
                <div>
                    <div class="footer-msg-phone">023-61501769</div>
                    <div>wciv@wciv.cn</div>
                    <div>重庆市高新区科学谷F3栋3楼</div>
                </div>

                <img src="@/assets/imgs/home/ewm.jpg" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AppFooter'
}
</script>
<style lang="scss" scoped>
  .home-footer {
    background-color: #0d0d0d;
    height: 192px;
    overflow: hidden;
    .home-footer-wrap {
        padding: 22px 0;
        @include contentWidth;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .k-link-ul {
            display: flex;
            flex-direction: row;
            margin-top: 14px;
            .click-li {
                cursor: pointer;
                color: #999999;
                font-size: 18px;
                margin-right: 48px;
                &:hover {
                    color: #fff;
                }
            }
        }

        .filing-msg{
            color: #676767;
            font-size: 14px;
            margin-top: 22px;
            >span{
                display: inline-block;
                line-height: 1.4;
                >img{
                    vertical-align: top;
                    margin-left: 5px;
                }
            }
            >img{
                width: 103px;
                height: 103px;
            }

            .white-link {
                color: #676767;
                &:hover{
                    color: #999999;
                }
            }
        }
        .contacts {
            text-align: right;
            display: flex;
            flex-direction: row;
            margin-top: 23px;

          > div {
            font-size: 14px;
            margin-top: 10px;
            font-family: 'OPPOSans-R';
            color: #999;
          }
          >img{
            width: 103px;
            height: 103px;
            margin-left: 16px;
          }
          .footer-msg-phone {
            font-size: 24px;
            color: #fff;
            margin-bottom: 20px;
            font-family: 'OPPOSans-B';
          }
        }
    }
  }
</style>