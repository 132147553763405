<template>
    <div :class="{'hidden':hidden, 'center':center}" class="pagination-container">
        <el-pagination
            :background="background"
            :current-page.sync="currentPage"
            :page-size.sync="pageSize"
            :layout="layout"
            :page-sizes="pageSizes"
            :total="total"
            v-bind="$attrs"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
    </div>
</template>

<script>
import { scrollTo } from '@/utils/scroll-to'

export default {
    name: 'MyPagination',
    props: {
        total: {
            required: true,
            type: Number
        },
        page: {
            type: Number,
            default: 1
        },
        limit: {
            type: Number,
            default: 20
        },
        pageSizes: {
            type: Array,
            default() {
                return [10, 20, 30, 50]
            }
        },
        layout: {
            type: String,
            default: 'total, sizes, prev, pager, next, jumper'
        },
        background: {
            type: Boolean,
            default: true
        },
        autoScroll: {
            type: Boolean,
            default: true
        },
        hidden: {
            type: Boolean,
            default: false
        },
        center: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        currentPage: {
            get() {
                return this.page
            },
            set(val) {
                this.$emit('update:page', val)
            }
        },
        pageSize: {
            get() {
                return this.limit
            },
            set(val) {
                this.$emit('update:limit', val)
            }
        }
    },
    watch: {
        total(val) {
            // 处理删除数据后，当前页数大于总页数，获取不到数据
            const totalPage = parseInt((val / this.limit).toFixed(0));
            if (val % this.limit === 0 && this.currentPage > 1 && totalPage < this.currentPage) {
                this.currentPage = totalPage || 1;
                this.$emit('pagination', { page: this.currentPage, limit: this.pageSize });
            }
        }
    },
    methods: {
        handleSizeChange(val) {
            this.$emit('pagination', { page: this.currentPage, limit: val })
            if (this.autoScroll) {
                scrollTo(0, 800)
            }
        },
        handleCurrentChange(val) {
            this.$emit('pagination', { page: val, limit: this.pageSize })
            if (this.autoScroll) {
                scrollTo(0, 800)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.pagination-container {
    // background: #fff;
    padding: 32px 16px;
    text-align: center;

    &.hidden {
        display: none;
    }

    &.center {
        text-align: center;
    }
    &.right {
        text-align: right;
    }
}
</style>
