<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
}
</script>
<style lang="scss">
</style>
