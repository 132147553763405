import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import component from './components';
import api from "@/api";
import dayjs from 'dayjs';

import "@/styles/index.scss";
import "@/styles/quill.bubble.css";
import 'animate.css';

Object.keys(component).forEach(key => {
    Vue.component(key, component[key]);
});

Vue.use(ElementUI);
Vue.prototype.$api = api

Vue.config.productionTip = false
Vue.prototype.$dayjs = dayjs;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
