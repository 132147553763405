<template>
    <div
        ref="flex-box"
        class="flex-box-wrapper"
        :class="[`is-${type}`]"
        :style="{
            gap,
            padding,
            'flex-wrap': nowrap ? 'nowrap' : 'wrap'
        }">
        <slot />
    </div>
</template>

<script>
export default {
    name: "FlexBox",
    props: {
        type: {
            type: String,
            default: "row",
        },
        number: {
            type: Number,
            default: 1,
        },
        column: { // 指定每列的大小，例如：1 1 100px，不带单位则是flex: 的值
            type: String,
            default: null
        },
        gap: {
            type: String,
            default: "0px",
            validator: (value) => value.indexOf("px") !== -1, // 必须用px作为单位
        },
        padding: {
            type: String,
            default: "0px",
        },
        nowrap: {
            type: Boolean,
            default: true,
        }
    },
    watch: {
        gap() {
            this.updateChildWidth()
        }
    },
    mounted() {
        this.updateChildWidth();
    },
    methods: {
        updateChildWidth() {
            const flexBoxNode = this.$refs['flex-box'];
            if (!flexBoxNode) return;

            var [gapHeight, gapWidth] = this.gap.split(" ").map(item => parseInt(item));
            gapWidth = gapWidth || gapHeight; // 如果只有一个值，那么就是水平间距
            // 给子级添加样式
            if (this.column) {
                const columnArr = this.column.split(" ");
                flexBoxNode.childNodes.forEach((item, index) => {
                    if (/^\d+$/.test(columnArr[index])) {
                        item.style.flex = columnArr[index];
                    } else {
                        item.style.width = columnArr[index];
                    }
                });
            } else {
                flexBoxNode.childNodes.forEach((item, index) => {
                    item.style.width = `calc((100% - ${(this.number - 1) * gapWidth}px) / ${this.number})`;
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.flex-box-wrapper {
    &.is-row {
        @include flexRow;
    }
    &.is-column {
        @include flexColumn;
    }
}
</style>