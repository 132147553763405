import Instance from "./instance";

export default {
    // -----------------------------------------------
    // 招标
    getTenderList(params) {
        return Instance.get('/app/system/tender/list', { params });
    },
    getTenderDetail(id) {
        return Instance.get(`/app/system/tender/${id}`);
    },
    // 新闻
    getNewsList(params) {
        return Instance.get('/app/system/news/list', { params });
    },
    getNewsDetail(id) {
        return Instance.get(`/app/system/news/${id}`);
    },
    // 横幅
    getBannerList(params) {
        return Instance.get('/app/system/banner/list', { params });
    },
}