<template>
    <div class="breadcrumb">
        <span class="local"><img class="img" src="@/assets/imgs/common/loca.png">您当前位置：</span>
        <el-breadcrumb separator="|">
            <!--面包屑列表-->
            <el-breadcrumb-item @click.native="breadcrumbClick('home')" style="cursor: pointer">首页</el-breadcrumb-item>
            <el-breadcrumb-item
                @click.native="breadcrumbClick"
                style="cursor: pointer"
            >
                <!-- {{ type === 'news' ? '新闻动态' : type === 'tenders' ? '信息公开':'科技创新' }} -->
                {{ type === 'news' ? '新闻动态' : type === 'tenders' ? '信息公开':type==='technology'?'科技创新':'' }}
            </el-breadcrumb-item>
            <el-breadcrumb-item class="active">{{title}}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
<script>
export default {
    name: "BreadcrumbPc",
    props: {
        isDetails: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {

        }
    },
    methods: {
        breadcrumbClick(path) {
            if (path === "home") {
                this.$router.push('/')
            } else {
                if (this.type === 'news') {
                    this.$router.push('/news')
                } else if (this.type === 'technology') {
                    this.$router.push('/technology')
                } else {
                    this.$router.push('/inviteTenders')
                }
            }
        }
    }
}
</script>
<style scoped lang='scss'>
.breadcrumb {
    background-color: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
    padding: 38px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    ::v-deep .el-breadcrumb__inner {
        font-size: 18px;
        color: #666666;
    }
    .active {
        ::v-deep .el-breadcrumb__inner {
            color: #333333;
            &:hover {
                color: #333333;
            }
        }
    }

.local {
    font-size: 18px;
    color: #333333;
    .img {
        margin-right: 4px;
        vertical-align: middle;
    }
}
}
</style>