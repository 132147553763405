<template>
    <div class="app-header-h5">
        <img class="logo" :src="require('@/assets/imgs/home/logo.png')" @click="$router.push('/')">
        <div class="menu-wrap" :class="{'no-collapse': noCollapse}">
            <div class="logo-wrap">
                <img class="close-btn" :src="require('@/assets/imgs/home/close.png')" @click="handleTriggerMenu">
                <img class="logo" :src="require('@/assets/imgs/home/logo-black.png')" @click="$router.push('/')">
            </div>
            <img class="menu-btn" :src="require('@/assets/imgs/home/menu.png')" @click="handleTriggerMenu">
            <div class="menu-list">
                <router-link class="menu-item" v-for="(item, index) in appMenu" :key="index" :to="item.path" @click.native="handleClickNav">{{ item.meta.title }}</router-link>
            </div>
            <div class="mark" @click="noCollapse = false"></div>
        </div>
    </div>
</template>

<script>
import { appMenu } from "@/router";
export default {
    name: "AppHeaderH5",
    data() {
        return {
            appMenu,
            noCollapse: false,
        }
    },
    methods: {
        handleTriggerMenu() {
            this.noCollapse = !this.noCollapse;
        },
        handleClickNav() {
            this.noCollapse = false;
        }
    }
}
</script>

<style lang="scss" scoped>
$header-height: 60px;

.app-header-h5 {
    position: relative;
    width: 100vw;
    height: $header-height;
    background: #1B3586;
    padding-left: 15px;
    @include flexMiddle;
    box-sizing: border-box;
    flex-shrink: 0;

    .logo {
        width: 120px;
    }
    .menu-wrap {
        position: absolute;
        top: 0;
        right: 0;
        width: calc(24px + 16px);
        height: $header-height;
        padding-right: 16px;
        transition: width .3s;
        @include flexAlign;
        box-sizing: border-box;

        .logo-wrap {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: $header-height;
            @include flexAlign;
            background: #fff;
            z-index: 10;
        }
        .menu-btn {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
        }
        .close-btn {
            position: absolute;
            left: 22px;
            top: 50%;
            transform: translateY(-50%);
        }
        .menu-list {
            position: absolute;
            left: 0px;
            top: 60px;
            width: 100%;
            background: #fff;
            padding: 0 14px 14px;
            box-sizing: border-box;
            z-index: 10;

            .menu-item {
                height: 48px;
                padding-left: 14px;
                font-size: 14px;
                color: #070707;
                @include flexMiddle;
                border-bottom: 1px solid #D9D9D9;
            }
        }
        .menu-list,
        .close-btn,
        .logo-wrap {
            display: none;
        }
        &.no-collapse {
            width: 100%;
            height: 100vh;

            .menu-list,
            .close-btn,
            .mark {
                display: block;
            }
            .logo-wrap {
                display: flex;
            }
            .menu-btn {
                display: none;
            }
        }
        .mark {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
            z-index: 5;
        }
    }
}
</style>