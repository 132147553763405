import axios from 'axios';
import { Message, Loading } from 'element-ui';

const Instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API || '',
    headers: {
        "content-type": "application/json;charset=UTF-8"
    },
    timeout: 1000 * 60,
});

// 请求加载动画
let loadingInstance = null;
function closeLoading() {
    loadingInstance.close();
}

// 多次相同提示信息黑名单
let blacklist = [];
const isOpenErrTips = function(msg) {
    if (!blacklist.includes(msg)) {
        blacklist.push(msg);
        Message({
            message: msg,
            type: 'error',
            duration: 5 * 1000
        });
        setTimeout(() => {
            blacklist = blacklist.filter(item => item != msg);
        }, 1000);
    }
}

Instance.interceptors.request.use((config) => {
    // const token = localStorage.getItem('token');
    // if (token) config.headers['Authorization'] = `Bearer ${token}`;

    // post请求没有参数，添加空对象
    if (config.method == 'post' && !config.data) {
        config.data = {};
    }

    // 没配置loading或者配置loading为true才使用loading
    if (!config.hasOwnProperty('loading') || config.loading === true) {
        loadingInstance = Loading.service({
            text: "加载中...",
            background: 'transparent'
        });
    }
    return config;
}, err => {
    closeLoading();
    return Promise.reject(err);
});

Instance.interceptors.response.use((response) => {
    if (response.status === 200 && response.data.code !== 200) {
        const { data } = response;

        isOpenErrTips(data.msg);
        // if ((data.code === -2 || data.code === -6)) {
        //     store.dispatch("user/clearUserInfo");
        //     if (data.code === -6) router.push("/login");
        //     else store.dispatch('user/goLogin');
        // }
        closeLoading();
        return Promise.reject(response.data);
    }
    closeLoading();
    return response.data;
}, error => {
    if (error.response) {
        const { status } = error.response;
        if (status >= 500) {
            isOpenErrTips('服务器异常');
        } else if (status >= 400 && status < 500) {
            isOpenErrTips(`请求错误：${status}`);
        }
    } else {
        if (error.message.indexOf('timeout') > -1) {
            isOpenErrTips('请求超时, 请重新尝试!');
        } else {
            isOpenErrTips('网络异常!');
        }
    }
    closeLoading();
    return Promise.reject(error);
});

export default Instance;