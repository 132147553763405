<template>
    <div class="sidebar-logo-container" :class="{'collapse':collapse}">
        <transition name="sidebarLogoFade">
            <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
                <img :src="require('@/assets/imgs/home/logo-s.png')" class="sidebar-logo">
            </router-link>
            <router-link v-else key="expand" class="sidebar-logo-link" to="/">
                <!-- <img :src="require('@/assets/imgs/logo.png')" class="sidebar-logo"> -->
                <!-- <h1 class="sidebar-title"><i>{{ expandTitle }}</i> </h1> -->
                <img :src="require('@/assets/imgs/home/logo-black.png')">
            </router-link>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'SidebarLogo',
    props: {
        collapse: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            expandLogo: null,
            expandTitle: '数智高速平台'
        }
    }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 48px;
  line-height: 50px;
  background: #fff;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #3361FF;
      font-weight: 600;
      line-height: 50px;
      font-size: 18px;
      font-family: Alimama_ShuHeiTi_Bold;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
