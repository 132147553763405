import Vue from 'vue'
import VueRouter from 'vue-router'
import AppIndex from '@/layout/appIndex';
import appMenuIndex from '@/layout/appMenuIndex';
import appRoute from "./appRoute";

Vue.use(VueRouter)

const routes = [
    {
        path: '/manage',
        component: appMenuIndex
    },
    {
        path: '/pdf',
        component: () => import('@/views/pdf')
    },
]

export const appMenu = [{
    path: "/",
    component: AppIndex,
    meta: {
        title: "首页"
    },
    children: [{
        path: "",
        component: () => import("@/views/home"),
        meta: {
            title: "首页"
        }
    }]
},
{
    path: "/contacts",
    component: AppIndex,
    meta: {
        title: "关于我们"
    },
    children: [{
        path: "",
        component: () => import("@/views/contacts"),
        meta: {
            title: "关于我们"
        }
    }]
},
{
    path: "/news",
    component: AppIndex,
    meta: {
        title: "新闻动态"
    },
    children: [{
        path: "",
        component: () => import("@/views/news"),
        meta: {
            title: "新闻动态"
        }
    },
    {
        path: "details",
        component: () => import("@/views/inviteTenders/details.vue"),
        meta: {
            title: "详情"
        }
    }]
},
{
    path: "/business",
    component: AppIndex,
    meta: {
        title: "主营业务"
    },
    children: [{
        path: "",
        component: () => import("@/views/business"),
        meta: {
            title: "主营业务"
        }
    }]
},
{
    path: "/technology",
    component: AppIndex,
    meta: {
        title: "科技创新"
    },
    children: [{
        path: "",
        component: () => import("@/views/technology"),
        meta: {
            title: "科技创新"
        }
    },
    {
        path: "details",
        component: () => import("@/views/inviteTenders/details.vue"),
        meta: {
            title: "详情"
        }
    }
    ]
},
{
    path: "/inviteTenders",
    component: AppIndex,
    meta: {
        title: "信息公开"
    },
    children: [{
        path: "",
        component: () => import("@/views/inviteTenders"),
        meta: {
            title: "信息公开",
        }
    },
    {
        path: "details",
        component: () => import("@/views/inviteTenders/details.vue"),
        meta: {
            title: "详情"
        }
    }]
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes.concat(appMenu, appRoute)
})

// 解决重复点击相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router
