<template>
    <div :class="classObj" class="app-container">
        <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
        <Sidebar class="sidebar-container" />
        <div class="main-container">
            <!-- <div :class="{'fixed-header':true}">
                <AppHeader :show-logo="false" />
            </div> -->
            <AppMain />
        </div>
        <img class="trigger-menu" :class="{'is-collapse': isCollapse}" :src="require('@/assets/imgs/menu/open-menu.png')" @click="handleTriggerMenu">
    </div>
</template>

<script>
// import AppHeader from './components/AppHeader'
import Sidebar from './components/Sidebar'
import AppMain from './components/AppMain'
export default {
    // components: { AppHeader, Sidebar, AppMain },
    components: { Sidebar, AppMain },
    computed: {
        fixedHeader() {
            return this.$store.state.settings.fixedHeader
        },
        sidebar() {
            return this.$store.state.app.sidebar
        },
        device() {
            return this.$store.state.app.device
        },
        classObj() {
            return {
                hideSidebar: !this.sidebar.opened,
                openSidebar: this.sidebar.opened,
                withoutAnimation: this.sidebar.withoutAnimation,
                mobile: this.device === 'mobile'
            }
        },
        isCollapse() {
            return !this.sidebar.opened
        }
    },
    methods: {
        handleClickOutside() {
            this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
        },
        handleTriggerMenu() {
            this.$store.dispatch('app/toggleSideBar')
        }
    }
}
</script>

<style lang="scss" scoped>
.app-container {
    @include clearfix;
    position: relative;
    height: 100vh;
    width: 100%;

    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
    .trigger-menu {
        position: absolute;
        top: calc(48px + 16px);
        left: $sideBarWidth;
        transform: translate(-50%, 0);
        z-index: 1002;
        cursor: pointer;
        user-select: none;
        transition: left 0.28s;

        &.is-collapse {
            left: 54px;
            transform: translate(-50%, 0) rotateY(180deg);
        }
    }
    .drawer-bg {
        background: #000;
        opacity: 0.3;
        width: 100%;
        top: 0;
        height: 100%;
        position: absolute;
        z-index: 999;
    }

    .fixed-header {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 9;
        width: calc(100% - #{$sideBarWidth});
        transition: width 0.28s;
    }

    &.hideSidebar .fixed-header {
        width: calc(100% - 54px);
    }

    &.mobile .fixed-header {
        width: 100%;
    }
}
</style>