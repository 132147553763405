<template>
    <div class="breadcrumb">
        <span class="local"><img class="img" src="@/assets/imgs/common/loca.png">您当前位置：</span>
        <el-breadcrumb separator=">">
            <!--面包屑列表-->
            <el-breadcrumb-item @click.native="breadcrumbClick('home')" style="cursor: pointer">首页</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="breadcrumbClick" style="cursor: pointer">
                {{ type === 'news' ? '新闻动态' : type === 'tenders' ? '信息公开':'科技创新' }}
            </el-breadcrumb-item>
            <el-breadcrumb-item v-if="isDetails">详情</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
<script>
export default {
    name: "BreadcrumbH5",
    props: {
        isDetails: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            default: null
        },
    },
    data() {
        return {

        }
    },
    methods: {
        breadcrumbClick(path) {
            if (path === "home") {
                this.$router.push('/')
            } else {
                if (this.type === 'news') {
                    this.$router.push('/news')
                } else if (this.type === 'technology') {
                    this.$router.push('/technology')
                } else {
                    this.$router.push('/inviteTenders')
                }
            }
        }
    }
}
</script>

<style scoped lang='scss'>
.breadcrumb {
    display: flex;
    flex-direction: row;
    ::v-deep .el-breadcrumb__inner {
        font-size: 12px;
        color: #666666;
    }
    .active {
        ::v-deep .el-breadcrumb__inner {
            color: #0052D9;
            &:hover {
                color: #0052D9;
            }
        }
    }

    .local {
        font-size: 12px;
        color: #333333;
        .img {
            margin-right: 4px;
            vertical-align: middle;
        }
    }
}
</style>