<template>
    <!-- <div class="h5-footer" :style="{'padding-top': offsetHeight + 'px'}"> -->
    <div class="h5-footer">
        <div ref="main" class="footer-main">
            <div class="footer-msg">
                <img class="logo" :src="require('@/assets/imgs/home/logo.png')" alt="" @click="$router.push('/')">
                <div class="name" @click="$router.push('/contacts')">关于我们</div>
                <div class="name" @click="$router.push('/news')">新闻动态</div>
                <div class="name" @click="$router.push('/business')">主营业务</div>
                <div class="name" @click="$router.push('/technology')">科技创新</div>
                <div class="name" @click="$router.push('/inviteTenders')">信息公开</div>
                <div class="value">023-61501769</div>
                <div class="email">wciv@wciv.cn</div>
                <div class="address">重庆市高新区科学谷F3栋3楼</div>
                <div class="ewm"><img src="@/assets/imgs/home/h5/ewm.png" /></div>
            </div>
            <div class="filing-info">
                Copyright &copy; 2023 西部车网（重庆）有限公司<br>
                <a href="https://beian.miit.gov.cn/" target="_blank" class="white-link">渝ICP备2023001677号</a>
                <div>
                    <img src="@/assets/imgs/common/20240711102336.png" height="16">
                    <a href="https://beian.mps.gov.cn/#/query/websearch?code=50009802001949" rel="noreferrer" target="_blank" class="white-link">渝公网安备50009802001949</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "H5Footer",
    data() {
        return {
            offsetHeight: 0,
        }
    },
    mounted() {
        this.offsetHeight = this.$refs['main'].offsetHeight;
    }
}
</script>

<style lang="scss" scoped>
.h5-footer {
    .footer-main {
        // position: absolute;
        // left: 0;
        // bottom: 0;
        width: 100%;
        @include flexColumn;
        align-items: left;
        background: #0D0D0D;
        .footer-msg {
            padding-top: 30px;
            padding-left: 16px;
        }
    }
    .logo {
        width: 116px;
        margin-bottom: 20px;
    }
    .name {
        font-size: 18px;
        color: rgba($color: #fff, $alpha: 0.8);
        font-family: OPPOSans-M;

        & + .name {
            margin-top: 16px;
        }
    }
    .value {
        font-size: 24px;
        color: rgba($color: #fff, $alpha: 0.8);
        margin-top: 30px;
    }
    .email {
        font-size: 14px;
        color: rgba($color: #fff, $alpha: 0.8);
        font-family: OPPOSans-M;
        margin-top: 20px;
    }
    .address {
        margin-top: 10px;
        color: rgba($color: #fff, $alpha: 0.8);
        font-size: 14px;
    }
    .ewm {
        margin-top: 30px;
    }
    .line {
        width: 100%;
        height: 1px;
        background: #393939;
        margin: 0 16px;
        margin-top: 28px;
    }
    .filing-info {
        background-color: #181818;
        color: #676767;
        font-size: 14px;
        margin-top: 40px;
        justify-content: left;
        padding: 10px 16px;
        line-height: 1.5;
        >a{
            color: #676767;
            display: block;
        }
        >div {
            img {
                vertical-align: top;
            }
            a{
                color: #676767;
            }
        }
    }
}
</style>