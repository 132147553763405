<template>
    <div class="app-main">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "AppMain",
}
</script>

<style lang="scss" scoped>
.app-main {
    width: 100%;
    // height: 100%;
    background: #F7F7F9;
}
.fixed-header + .app-main {
    padding-top: 130px;
}
</style>